import {Checkbox, Drawer, FormControlLabel, FormGroup, useTheme} from "@mui/material"
import {DragDropContext, Draggable, Droppable, DropResult} from '@hello-pangea/dnd';
import {useCompoundTableStore, useUIStore} from "../store/store"
import {DrawerHeader} from "./Layout"

export const ColumnDrawer = (
    {open, onClose}: { open: boolean, onClose: () => void }
) => {
    const {palette} = useTheme()

    const {themeMode} = useUIStore()
    const {
        columnOrder,
        setColumnOrder,
        columnVisibility,
        toggleColumnVisibility
    } = useCompoundTableStore()

    const handleClick = (column: string) => () => toggleColumnVisibility(column)

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const element = columnOrder[result.source.index];
        const newColumnOrder = columnOrder
        newColumnOrder.splice(result.source.index, 1);
        newColumnOrder.splice(result.destination.index, 0, element);

        setColumnOrder(newColumnOrder)
    }

    const backgroundColor = themeMode === 'light' ? palette.primary : palette.secondary

    return (
        <Drawer
            anchor="top"
            open={open}
            onClose={onClose}
        >
            <DrawerHeader/>
            <DragDropContext onDragEnd={handleDragEnd}>
                <FormGroup row={true}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                style={{
                                    display: 'flex',
                                    padding: 2,
                                    overflow: 'auto',
                                }}
                                {...provided.droppableProps}
                            >
                                {columnOrder.map((column, index) => (
                                    <Draggable
                                        key={column}
                                        draggableId={column}
                                        index={index}
                                        isDragDisabled={column === 'ID' || column === 'Structure'}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                key={column}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    display: 'flex',
                                                    userSelect: 'none',
                                                    border: `black 1px solid`,
                                                    borderRadius: '20px',
                                                    padding: '0 12px',
                                                    margin: '8px 8px 8px 0',
                                                    background: snapshot.isDragging ? backgroundColor.dark : backgroundColor.light,
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <FormControlLabel key={column} control={
                                                    <Checkbox
                                                        key={column}
                                                        checked={columnVisibility[column] ?? true}
                                                        onClick={handleClick(column)}
                                                        color={themeMode === 'light' ? 'secondary' : 'primary'}
                                                    />}
                                                                  label={column}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </FormGroup>
            </DragDropContext>
        </Drawer>
    )
}