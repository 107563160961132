/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {Body_upload_sdf_compound_upload_sdf_post} from '../models/Body_upload_sdf_compound_upload_sdf_post';
import type {CompoundWithRelationships} from '../models/CompoundWithRelationships';
import type {CreateCompound} from '../models/CreateCompound';
import type {GenerateXLSX} from '../models/GenerateXLSX';
import type {UpdateCompound} from '../models/UpdateCompound';

import type {CancelablePromise} from '../core/CancelablePromise';
import type {BaseHttpRequest} from '../core/BaseHttpRequest';

export class CompoundService {

    constructor(public readonly httpRequest: BaseHttpRequest) {
    }

    /**
     * Get Compounds
     * @param skip
     * @param limit
     * @param sort
     * @param reverse
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public getCompounds(
        skip?: number,
        limit: number = 100,
        sort?: string,
        reverse: boolean = false,
    ): CancelablePromise<Array<CompoundWithRelationships>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/compound/get',
            query: {
                'skip': skip,
                'limit': limit,
                'sort': sort,
                'reverse': reverse,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Compound
     * @param compoundId
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public getCompound(
        compoundId: number,
    ): CancelablePromise<CompoundWithRelationships> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/compound/get/{compound_id}',
            path: {
                'compound_id': compoundId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Compound
     * @param requestBody
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public createCompound(
        requestBody: CreateCompound,
    ): CancelablePromise<CompoundWithRelationships> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Compound
     * @param requestBody
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public updateCompound(
        requestBody: UpdateCompound,
    ): CancelablePromise<CompoundWithRelationships> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Compound
     * @param compoundId
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public deleteCompound(
        compoundId: number,
    ): CancelablePromise<CompoundWithRelationships> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/compound/delete/{compound_id}',
            path: {
                'compound_id': compoundId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Sdf
     * @param propertyMap
     * @param formData
     * @returns CompoundWithRelationships Successful Response
     * @throws ApiError
     */
    public uploadSdf(
        propertyMap: string,
        formData: Body_upload_sdf_compound_upload_sdf_post,
    ): CancelablePromise<Array<CompoundWithRelationships>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound/upload-sdf',
            query: {
                'property_map': propertyMap,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Xlsx
     * @param requestBody
     * @returns any xlsx
     * @throws ApiError
     */
    public generateXlsx(
        requestBody: GenerateXLSX,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound/generate-xlsx',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
