import {create} from "zustand";
import {RDKitModule} from "@rdkit/rdkit";
import {createJSONStorage, devtools, persist} from "zustand/middleware"
import {functionalUpdate, OnChangeFn, SortingState, VisibilityState} from "@tanstack/react-table";

interface RdkitStore {
    rdkit: RDKitModule | null
    setRdkit: () => void
}

export const useRdkitStore = create<RdkitStore>()(
    (set: any, get: any) => ({
        rdkit: null,
        setRdkit: async () => {
            const currState = get().rdkit
            if (!currState) {
                window.initRDKitModule().then((RDKit) => {
                    set({rdkit: RDKit})
                })
                    .catch((e: any) => {
                        console.log(e)
                        set({rdkit: false})
                    });
            }
        }
    })
)

interface UIStore {
    drawerOpen: boolean
    toggleDrawerOpen: () => void
    menuOpen: boolean
    toggleMenuOpen: () => void
    themeMode: 'light' | 'dark'
    toggleThemeMode: () => void
}

export const useUIStore = create<UIStore>()(
    devtools(
        persist(
            (set) => ({
                drawerOpen: false,
                toggleDrawerOpen: () => set((state) => ({drawerOpen: !state.drawerOpen})),
                menuOpen: false,
                toggleMenuOpen: () => set((state) => ({menuOpen: !state.menuOpen})),
                themeMode: 'light',
                toggleThemeMode: () => set((state) => ({themeMode: (state.themeMode === 'light' ? 'dark' : 'light')}))
            }), {
                name: 'ui-state-storage',
                storage: createJSONStorage(() => window.localStorage)
            }
        )
    )
)

interface CompoundTableStore {
    sorting: SortingState
    setSorting: OnChangeFn<SortingState> | undefined
    columnOrder: string[]
    setColumnOrder: (newOrder: string[]) => void
    columnVisibility: VisibilityState
    toggleColumnVisibility: (column: string) => void
}

export const useCompoundTableStore = create<CompoundTableStore>()(
    devtools(
        persist(
            (set) => ({
                sorting: [{id: "id", desc: true}],
                setSorting: (updater) => set((state) => ({sorting: functionalUpdate(updater, state.sorting)})),
                columnOrder: [],
                setColumnOrder: (newOrder) => set(() => ({columnOrder: newOrder})),
                columnVisibility: {},
                toggleColumnVisibility: (column) => {
                    set((state) => {
                        let newColumnVisibility = state.columnVisibility
                        column in state.columnVisibility ? newColumnVisibility[column] = !newColumnVisibility[column] : newColumnVisibility[column] = false
                        return {columnVisibility: newColumnVisibility}
                    })
                }
            }), {
                name: 'table-state-storage',
                storage: createJSONStorage(() => window.localStorage)
            }
        )
    )
)

