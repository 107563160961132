import {Buffer} from "buffer/";
import {saveAs} from 'file-saver'
import {Download, FilterAlt, ViewColumn} from '@mui/icons-material';
import {CircularProgress, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {ColumnDef} from '@tanstack/react-table';
import {useMemo, useState} from 'react';
import {ColumnDrawer} from '../components/ColumnDrawer';
import {CompoundTable} from "../components/CompoundTable";
import {FilterDrawer} from '../components/FilterDrawer';
import SmilesStructure from '../components/SmilesStructure';
import {CompoundWithRelationships} from '../requests';
import {useCompoundTableStore} from '../store/store';
import useApiClient from "../hooks/apiClient";


const HomePage = () => {
    const {apiClient} = useApiClient()
    const {sorting, columnOrder, columnVisibility} = useCompoundTableStore()
    const [fetching, setFetching] = useState(false);
    const [columnDrawer, setColumnDrawer] = useState(false)
    const [filterDrawer, setFilterDrawer] = useState(false)
    const propertyData = useQuery({
        queryKey: ['compoundProperties'],
        queryFn: async () => {
            return apiClient.compoundProperty.getCompoundProperties();
        },
    })


    const fetchSize = 25
    const sortProp = sorting[0]?.id === 'id' ? undefined : sorting[0]?.id
    const sortDirection = !sorting[0]?.desc

    const {status, error, data, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading} =
        useInfiniteQuery<CompoundWithRelationships[]>(
            ['compounds', sorting],
            async ({pageParam = 0}) => {
                const offset = pageParam * fetchSize
                return apiClient.compound.getCompounds(offset, fetchSize, sortProp, sortDirection);
            },
            {
                getNextPageParam: (_lastGroup, groups) => _lastGroup.length === 0 ? undefined : groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            }
        )

    const compoundData = useMemo(
        () => data?.pages?.flatMap(page => page) ?? [],
        [data]
    )

    const generateXlsx = async () => {
        if (fetching) {
            return
        }
        setFetching(true)
        const order: string[] = []
        columnOrder.forEach((column) => {
            if (columnVisibility[column] ?? true) {
                order.push(column)
            }
        })

        await apiClient.compound.generateXlsx({
            sort: sortProp,
            reverse: sortDirection,
            column_order: order
        }).then((fileData) => {
            const buffer = Buffer.from(fileData, "base64");
            return new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        }).then(async (blob) => {
            saveAs(blob, 'generated-sheet.xlsx');
            setFetching(false)
        })

    }


    const baseColumns: ColumnDef<CompoundWithRelationships>[] = useMemo(() => [
        {
            id: 'ID',
            accessorFn: (compound) => `OB-TEST-${compound.id}`,
            header: 'ID',
        },
        {
            id: 'Structure',
            header: 'Structure',
            cell: ({row}) => {
                return <SmilesStructure id={row.original.id} smiles={row.original.smiles}/>
            },
            enableSorting: false
        },
        {
            id: 'SMILES',
            accessorKey: 'smiles',
            header: 'SMILES',
            enableSorting: false
        },
        {
            id: 'InChiKey',
            accessorKey: 'inchikey',
            header: 'InChiKey',
            enableSorting: false
        }
    ], [])

    const columns = useMemo<ColumnDef<CompoundWithRelationships>[]>(
        () => baseColumns.concat(propertyData.data?.map((prop) => {
            return {
                accessorFn: (datum: CompoundWithRelationships) => datum.compound_property_map[prop.name],
                accessorKey: `compound_property_map.${prop.name}`,
                id: prop.name,
                header: prop.name,
            }
        }) ?? []),
        [propertyData, baseColumns]
    )

    if (status === 'error') {
        return <div>{`Error: ${error}`}</div>
    }

    return (
        <>
            <ColumnDrawer open={columnDrawer} onClose={() => setColumnDrawer(false)}/>
            <FilterDrawer open={filterDrawer} onClose={() => setFilterDrawer(false)}/>
            <CompoundTable
                compoundData={compoundData}
                columns={columns}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                isInitialLoading={isInitialLoading}
            />
            <SpeedDial
                ariaLabel='speed-dial'
                sx={{position: 'fixed', bottom: 24, right: 24}}
                icon={<SpeedDialIcon/>}
            >
                <SpeedDialAction
                    key="column"
                    icon={<ViewColumn/>}
                    tooltipTitle={"Edit Columns"}
                    onClick={() => setColumnDrawer(true)}
                />
                <SpeedDialAction
                    key="filter"
                    icon={<FilterAlt/>}
                    tooltipTitle={"Filters"}
                    onClick={() => setFilterDrawer(true)}
                />
                <SpeedDialAction
                    key="export"
                    icon={fetching ? <CircularProgress/> : <Download/>}
                    tooltipTitle={"Export to XLSX"}
                    onClick={generateXlsx}
                />
            </SpeedDial>
        </>
    )
}

export default HomePage
