import {ReactLocation, Route} from "react-location";
import HomePage from "./pages/HomePage";
import OrdersPage from "./pages/OrdersPage";
import UploadPage from "./pages/UploadPage";
import CallbackPage from "./pages/CallbackPage";
import AuthenticationGuard from "./components/AuthenticationGuard";

export const routes: Route[] = [
    {
        path: "/",
        element: <AuthenticationGuard component={HomePage}/>,
    },
    {
        path: "/orders",
        element: <AuthenticationGuard component={OrdersPage}/>
    },
    {
        path: "/upload",
        element: <AuthenticationGuard component={UploadPage}/>
    },
    {
        path: "/callback",
        element: <CallbackPage/>
    }
];

export const location = new ReactLocation();