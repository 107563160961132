import {withAuthenticationRequired} from "@auth0/auth0-react";
import {ComponentType} from "react";
import {Layout} from "./Layout";
import Spinner from "./Spinner";

const AuthenticationGuard = ({component}: { component: ComponentType }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <Layout><Spinner size={300} height={"80vh"} width={"100vw"}/></Layout>
        ),
    });

    return <Component/>;
}

export default AuthenticationGuard
