/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

/**
 * An enumeration.
 */
export enum CompoundPropertyType {
    STRING = 'STRING',
    INT = 'INT',
    FLOAT = 'FLOAT',
}
