import {AppState, Auth0Provider as BaseAuth0Provider} from "@auth0/auth0-react";
import {ReactNode} from "react";
import {useNavigate} from "react-location";
import {Config} from "../config";

export const Auth0Provider = ({children}: { children: ReactNode }): JSX.Element | null => {

    const audience = Config.audience;
    const redirectUri = Config.redirectUri;
    const clientId = Config.clientId;
    const domain = Config.domain;

    const navigate = useNavigate()
    const onRedirectCallback = (appState?: AppState) => {
        navigate({to: appState?.returnTo || window.location.pathname, replace: true});
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <BaseAuth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </BaseAuth0Provider>
    );
};