import {Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@mui/material";
import TableSortLabel from '@mui/material/TableSortLabel';
import {memo, ReactNode, useEffect, useState} from "react";
import {CompoundWithRelationships} from "../requests";
import {useCompoundTableStore, useUIStore} from "../store/store";
import {ColumnDef, flexRender, getCoreRowModel, SortDirection, useReactTable} from '@tanstack/react-table';
import {useInView} from "react-intersection-observer";
import Spinner from "./Spinner";

type CompoundCellTuple = {
    header: string;
    content: (JSX.Element | ReactNode);
}

const CompoundRow = memo(({compoundId, cells}: {
    cells: CompoundCellTuple[],
    compoundId: string
}): JSX.Element => {
    return (
        <TableRow key={`${compoundId}-row`}>
            {cells.map((cell, index) => <CompoundCell key={`${compoundId}-${index}-cell`} header={cell.header}
                                                      content={cell.content}
                                                      compoundId={compoundId} index={index}/>)}
        </TableRow>)
})

const CompoundCell = memo(({content, compoundId, index, header}: {
    content: JSX.Element | ReactNode,
    compoundId: string,
    index: number,
    header: string
}): JSX.Element => {
    const {themeMode} = useUIStore()
    const {palette} = useTheme()

    const [isHover, setIsHover] = useState(false);
    const isStructure = header === "Structure"
    const handleMouseEnter = () => {
        if (isStructure) return;
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    }

    return <TableCell
        sx={{
            padding: '0 0 0 8px',
            fontSize: 14,
            maxWidth: isStructure ? 260 : 150,
            overflow: 'hidden',
            textOverflow: "ellipsis",
            cursor: isStructure ? "default" : "copy",
            backgroundColor: isHover ? themeMode === 'light' ? palette.grey[200] : palette.grey[700] : palette.background.default
        }}
        key={`${compoundId}-${index}-cell`}
        onClick={(e) => {
            navigator.clipboard.writeText(e.currentTarget.innerText)
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        {content}
    </TableCell>
})

export const CompoundTable = (
    {
        compoundData,
        columns,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isInitialLoading,
    }: {
        compoundData: CompoundWithRelationships[],
        columns: ColumnDef<CompoundWithRelationships>[],
        fetchNextPage: () => void,
        hasNextPage?: boolean,
        isFetchingNextPage: boolean,
        isInitialLoading: boolean,
    }
) => {
    const {palette} = useTheme()
    const {themeMode} = useUIStore()
    const {
        sorting,
        setSorting,
        columnOrder,
        setColumnOrder,
        columnVisibility,
    } = useCompoundTableStore()

    useEffect(() => {
        if (columnOrder.length < columns.length) {
            setColumnOrder(columns.map((column) => column.id!))
        }
    }, [columns, columnOrder.length, setColumnOrder])

    const parseSortDirection = (sortDirection: false | SortDirection): 'asc' | 'desc' | undefined => {
        if (sortDirection === false) return undefined;
        return sortDirection === 'asc' ? 'asc' : 'desc'
    }

    const table = useReactTable({
        data: compoundData,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
        enableMultiSort: false,
        debugTable: true,
        state: {columnOrder: columnOrder, columnVisibility: columnVisibility, sorting: sorting},
        onSortingChange: setSorting,
        sortDescFirst: true
    })

    const {rows} = table.getRowModel()

    const [loaderRef, inView] = useInView()

    const [activeLoader, setActiveLoader] = useState(true);

    useEffect(() => {
        if (activeLoader && inView && hasNextPage && !isFetchingNextPage) {
            setActiveLoader(false)
            fetchNextPage()
        }
    }, [activeLoader, inView, hasNextPage, isFetchingNextPage, fetchNextPage])

    useEffect(() => {
        if (!inView) {
            setActiveLoader(true)
        }
    }, [inView, hasNextPage])

    return (
        <div className="container">
            <Table stickyHeader={true}>
                <TableHead key="table-head">
                    <TableRow key={'header'} sx={{height: 70}}>
                        {!isInitialLoading && table.getFlatHeaders().map(header => (
                            <TableCell key={`header ${header.id}`} sx={{
                                backgroundColor: themeMode === 'dark' ? palette.secondary.dark : "#D3D3D3",
                                padding: "10px 0",
                                maxWidth: 100,
                            }}>
                                <TableSortLabel
                                    active={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                    direction={parseSortDirection(header.column.getIsSorted()) ?? 'desc'}
                                    disabled={!header.column.getCanSort()}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 0
                                    }}
                                >
                                    <Typography variant="subtitle2"
                                                sx={{paddingTop: 0}}><>{header.column.columnDef?.header}</>
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody
                    key="table-body"
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {isInitialLoading ?
                        <TableRow key='loader-row'>
                            <TableCell key='loader'>
                                <Spinner size={300} height={"80vh"} width={"100vw"}/>
                            </TableCell>
                        </TableRow>
                        : <>
                            {
                                rows.map((row) => {
                                    const cells: CompoundCellTuple[] = row.getVisibleCells().map(cell => {
                                        return {
                                            header: cell.column.columnDef.header as string,
                                            content: flexRender(cell.column.columnDef.cell, cell.getContext())
                                        }
                                    })
                                    return <CompoundRow
                                        key={`${row.original.inchikey}`}
                                        compoundId={row.original.id.toString()}
                                        cells={cells}
                                    />
                                })
                            }
                            <TableRow key="footer-row" ref={loaderRef}>
                                <TableCell key="footer" variant="footer" colSpan={table.getVisibleFlatColumns().length}>
                                    <div style={{
                                        width: "100%",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        overflow: 'hidden',
                                        textOverflow: "ellipsis",
                                    }}>
                                        {hasNextPage ?
                                            <><Spinner size={50} height={50}/> Loading More... </>
                                            : "No More Results"}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </>
                    }
                </TableBody>
            </Table>
        </div>
    );
};
