/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {BaseHttpRequest} from './core/BaseHttpRequest';
import type {OpenAPIConfig} from './core/OpenAPI';
import {FetchHttpRequest} from './core/FetchHttpRequest';

import {CompoundService} from './services/CompoundService';
import {CompoundOrderService} from './services/CompoundOrderService';
import {CompoundPropertyService} from './services/CompoundPropertyService';
import {DefaultService} from './services/DefaultService';
import {HealthService} from './services/HealthService';
import {ProjectService} from './services/ProjectService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly compound: CompoundService;
    public readonly compoundOrder: CompoundOrderService;
    public readonly compoundProperty: CompoundPropertyService;
    public readonly default: DefaultService;
    public readonly health: HealthService;
    public readonly project: ProjectService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.compound = new CompoundService(this.request);
        this.compoundOrder = new CompoundOrderService(this.request);
        this.compoundProperty = new CompoundPropertyService(this.request);
        this.default = new DefaultService(this.request);
        this.health = new HealthService(this.request);
        this.project = new ProjectService(this.request);
    }
}

