import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material"
import {useQuery} from "@tanstack/react-query"
import React, {useState} from "react"
import {Body_parse_sdf_compound_property_parse_sdf_post, CompoundPropertyType} from "../requests"
import Spinner from "./Spinner"
import useApiClient from "../hooks/apiClient";
import {useUIStore} from "../store/store";


export const SdfUploadList = ({file}: { file: File }) => {
    const {apiClient} = useApiClient()
    const {themeMode} = useUIStore()

    const [uploading, setUploading] = useState(false);
    const [propertyMap, setPropertyMap] = useState<{ [key: string]: string }>({})
    const {data: propertyData} = useQuery({
        queryKey: ['compoundProperties'],
        queryFn: async () => {
            return apiClient.compoundProperty.getCompoundProperties();
        },
    })

    const mapToProperty = (propertyName: string, value: string) => {
        setPropertyMap({...propertyMap, [propertyName]: value})
    }

    const {
        data: parsedProperties,
        error: parsedPropertiesError,
        status: parsedPropertiesStatus
    } = useQuery({
        queryKey: ['parseSdf'],
        queryFn: async () => {
            const body: Body_parse_sdf_compound_property_parse_sdf_post = {file: file}
            return apiClient.compoundProperty.parseSdf(body);
        }
    })

    if (parsedPropertiesStatus === "error") {
        return <div>{`Error: ${parsedPropertiesError}`}</div>
    }
    if (parsedPropertiesStatus === "loading") {
        return <Spinner/>
    }

    const propertiesListToOptions: SelectPropertyOption[] | undefined = propertyData?.map(
        (property) => ({name: property.name})
    )

    return (
        <>
            <Box sx={{height: '60vh'}} display="flex" alignItems={"flex-start"} flexDirection="column" flexWrap='wrap'>
                {parsedProperties.map((parsedProperty) =>
                    <SelectPropertyField
                        parsedPropertyName={parsedProperty}
                        propertyList={propertiesListToOptions}
                        mapToProperty={(property: string) => mapToProperty(parsedProperty, property)}
                        key={parsedProperty}
                    />
                )}
            </Box>
            <Button
                variant="contained"
                color={themeMode === 'dark' ? "secondary" : "primary"}
                sx={{ height: 50, width: 100}}
                onClick={async () => {
                    setUploading(true)
                    await apiClient.compound.uploadSdf(
                        JSON.stringify(propertyMap),
                        {file: file}
                    ).catch((error) => {setUploading(false)})
                    setUploading(false)
                }}>
                {uploading ? <Spinner size={30} height={50} width={100}/> : "Upload"}
            </Button>
        </>
    )
}

interface SelectPropertyOption {
    inputValue?: string;
    name: string;
}

const filter = createFilterOptions<SelectPropertyOption>({trim: true});

const SelectPropertyField = (
    {
        parsedPropertyName,
        propertyList,
        mapToProperty,
    }:
        {
            parsedPropertyName: string,
            propertyList: SelectPropertyOption[] | undefined,
            mapToProperty: (property: string) => void
        }
) => {
    const {apiClient} = useApiClient()
    const [value, setValue] = useState<SelectPropertyOption | null>(null);
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        name: '',
        type: CompoundPropertyType.STRING
    });


    const handleClose = () => {
        setDialogValue({
            name: '',
            type: CompoundPropertyType.STRING
        });
        toggleOpen(false);
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValue({
            name: dialogValue.name,
            inputValue: dialogValue.name
        });
        await apiClient.compoundProperty.createCompoundProperty(
            {
                name: dialogValue.name,
                property_type: dialogValue.type
            })
        mapToProperty(dialogValue.name)
        handleClose();
    };

    return (
        <>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                ...dialogValue,
                                name: newValue
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            ...dialogValue,
                            name: newValue.inputValue
                        });
                    } else {
                        newValue && mapToProperty(newValue.name)
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id={`property-select-${parsedPropertyName}`}
                options={propertyList ?? []}
                getOptionLabel={(option) => {
                    // e.g. value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                sx={{width: 300, padding: '10px 0', margin: 1}}
                freeSolo
                renderInput={(params) => <TextField {...params} label={parsedPropertyName}/>}
            />
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle align="center">Create a new property</DialogTitle>
                    <DialogContent>
                        <FormControl sx={{margin: 1, '> *': {margin: 1}}}>
                            <InputLabel id='type-label'>Type</InputLabel>
                            <Select
                                labelId='type-label'
                                label='Type'
                                value={dialogValue.type}
                                sx={{height: '100%'}}
                                onChange={(event) => setDialogValue({
                                    ...dialogValue,
                                    type: event.target.value as CompoundPropertyType
                                })}>
                                {
                                    Object.keys(CompoundPropertyType).map(
                                        (type) => {
                                            return <MenuItem key={type} value={type}>{type.toLowerCase()}</MenuItem>
                                        }
                                    )
                                }
                            </Select>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                value={dialogValue.name}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        name: event.target.value,
                                    })
                                }
                                label="Name"
                                type="text"
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
