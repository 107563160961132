import {useAuth0} from "@auth0/auth0-react";
import {ApiClient} from "../requests"
import {Config} from "../config";


const useApiClient = () => {
    const {getAccessTokenSilently} = useAuth0()
    const token = getAccessTokenSilently()
    const apiClient = new ApiClient({
        BASE: `${Config.backendUrl}`,
        TOKEN: async () => await token
    })

    return {apiClient}
}


export default useApiClient
