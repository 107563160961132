import {CSSProperties} from "react";
import {useUIStore} from "../store/store";

const createAnimation = (loaderName: string, frames: string, suffix: string): string => {
    const animationName = `react-spinners-${loaderName}-${suffix}`;

    if (typeof window == "undefined" || !window.document) {
        return animationName;
    }

    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    const keyFrames = `
    @keyframes ${animationName} {
      ${frames}
    }
  `;

    if (styleSheet) {
        styleSheet.insertRule(keyFrames, 0);
    }

    return animationName;
};

const puff = [
    createAnimation("PuffLoader", "0% {transform: scale(0)} 100% {transform: scale(1.0)}", "puff-1"),
    createAnimation("PuffLoader", "0% {opacity: 1} 100% {opacity: 0}", "puff-2"),
];

function Spinner({
                     speedMultiplier = 1,
                     height = 100,
                     width = 100,
                     size = 60,
                 }: {
    speedMultiplier?: number,
    height?: number | string,
    width?: number | string,
    size?: number | string,
}): JSX.Element | null {

    const themeMode = useUIStore((state: any) => state.themeMode)
    const color = themeMode === 'dark' ? '#ffc107' : '#121212'

    const wrapper: CSSProperties = {
        display: "inherit",
        width: size,
        height: size,
        position: "relative",
    };

    const style = (i: number): CSSProperties => {
        return {
            position: "absolute",
            height: size,
            width: size,
            border: `thick solid ${color}`,
            borderRadius: "50%",
            opacity: "1",
            top: "0",
            left: "0",
            animationFillMode: "both",
            animation: `${puff[0]}, ${puff[1]}`,
            animationDuration: `${2 / speedMultiplier}s`,
            animationIterationCount: "infinite",
            animationTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.3, 0.61, 0.355, 1)",
            animationDelay: i === 1 ? "-1s" : "0s",
        };
    };

    return (
        <div style={{height: height, width: width, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <span style={wrapper}>
        <span style={style(1)}/>
        <span style={style(2)}/>
      </span>
        </div>
    );
}

export default Spinner;
