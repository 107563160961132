import {Box, useTheme} from "@mui/material";
import {useState} from "react";
import FileUpload from 'react-material-file-upload';
import {SdfUploadList} from "../components/SdfUploadList";


const UploadPage = () => {
    const {palette} = useTheme()

    const [file, setFile] = useState<File | undefined>(undefined);

    const handleFileUpload = (files: File[]) => {
        setFile(files[0])
    }

    return (
        <Box padding={4} display={'flex'} flexDirection={'column'} alignItems={'left'}>
            <FileUpload sx={{
                borderColor: palette.primary.main,
                '&:hover': {
                    borderColor: palette.primary.dark,
                },
                color: palette.primary.main,
                width: "50vw"
            }}
                        title={""}
                        buttonText={"Upload SDF"}
                        multiple={false}
                        value={file ? [file] : []}
                        onChange={handleFileUpload}
                        disabled={file !== undefined}
            />
            {file && <SdfUploadList file={file}/>}
        </Box>
    )
}

export default UploadPage
