import {useAuth0} from "@auth0/auth0-react";
import {Layout} from "../components/Layout";
import Spinner from "../components/Spinner";

const CallbackPage = () => {
    const {error} = useAuth0();

    if (error) {
        return (
            <Layout>
                <div className="content-layout">
                    <h1 id="page-title" className="content__title">
                        Error
                    </h1>
                    <div className="content__body">
                        <p id="page-description">
                            <span>{error.message}</span>
                        </p>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <Spinner size={300} height={"80vh"} width={"100vw"}/>
        </Layout>
    );
}

export default CallbackPage