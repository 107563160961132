import {memo, useCallback, useMemo, useState} from "react";
import {JSMol} from "@rdkit/rdkit";
import {useRdkitStore, useUIStore} from "../store/store";
import Spinner from "./Spinner";


const SmilesStructure = ({id, className = "", smiles}: { id: number, className?: string, smiles: string }) => {
    const rdkit = useRdkitStore((state: any) => state.rdkit)
    const [svg, setSvg] = useState("")
    const themeMode = useUIStore((state: any) => state.themeMode)

    const setSvgWithCallback = useCallback(() => {
        if (rdkit) {
            const mol: JSMol = rdkit.get_mol(smiles);
            const svg = mol.get_svg();
            setSvg(svg);
            mol.delete();
        }
    }, [smiles, rdkit]);


    useMemo(() => setSvgWithCallback(), [setSvgWithCallback])


    var svgToRender = svg

    if (themeMode === 'dark') {
        svgToRender = svg.replaceAll('000000', 'ffc107').replaceAll('FFFFFF', '121212')
    }

    if (!svgToRender) {
        return <Spinner key={smiles} size={100} height={200} width={250}/>
    }

    return (
        <div
            key={smiles}
            id={id.toString()}
            style={{width: 250, height: 200}}
            title={smiles}
            className={"smiles-structure " + className}
            dangerouslySetInnerHTML={{__html: svgToRender}}
        ></div>
    )
}

export default memo(SmilesStructure);

