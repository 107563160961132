import {Drawer, List, ListItem, Typography} from "@mui/material"
import {DrawerHeader} from "./Layout"

export const FilterDrawer = (
    {open, onClose}: { open: boolean, onClose: () => void }
) => {
    return (
        <Drawer
            anchor="top"
            open={open}
            onClose={onClose}
        >
            <DrawerHeader/>
            <Typography variant="h3">Filters</Typography>
            <List>
                <ListItem>Test-Filter-Include</ListItem>
                <ListItem>Test-Filter-Exclude</ListItem>
            </List>
        </Drawer>
    )
}
