/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {CompoundOrder} from '../models/CompoundOrder';
import type {CompoundOrderWithCompounds} from '../models/CompoundOrderWithCompounds';
import type {CreateCompoundOrder} from '../models/CreateCompoundOrder';
import type {UpdateCompoundOrder} from '../models/UpdateCompoundOrder';

import type {CancelablePromise} from '../core/CancelablePromise';
import type {BaseHttpRequest} from '../core/BaseHttpRequest';

export class CompoundOrderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {
    }

    /**
     * Get Compound Orders
     * @returns CompoundOrderWithCompounds Successful Response
     * @throws ApiError
     */
    public getCompoundOrders(): CancelablePromise<Array<CompoundOrderWithCompounds>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/compound-order/get',
        });
    }

    /**
     * Get Compound Order
     * @param compoundOrderId
     * @returns CompoundOrderWithCompounds Successful Response
     * @throws ApiError
     */
    public getCompoundOrder(
        compoundOrderId: number,
    ): CancelablePromise<CompoundOrderWithCompounds> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/compound-order/get/{compound_order_id}',
            path: {
                'compound_order_id': compoundOrderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Compound Order
     * @param requestBody
     * @returns CompoundOrder Successful Response
     * @throws ApiError
     */
    public createCompoundOrder(
        requestBody: CreateCompoundOrder,
    ): CancelablePromise<CompoundOrder> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound-order/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Compound Order
     * @param requestBody
     * @returns CompoundOrderWithCompounds Successful Response
     * @throws ApiError
     */
    public updateCompoundOrder(
        requestBody: UpdateCompoundOrder,
    ): CancelablePromise<CompoundOrderWithCompounds> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound-order/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Compound Order
     * @param compoundOrderId
     * @returns CompoundOrderWithCompounds Successful Response
     * @throws ApiError
     */
    public deleteCompoundOrder(
        compoundOrderId: number,
    ): CancelablePromise<CompoundOrderWithCompounds> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/compound-order/delete/{compound_order_id}',
            path: {
                'compound_order_id': compoundOrderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
