/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {
    Body_parse_sdf_compound_property_parse_sdf_post
} from '../models/Body_parse_sdf_compound_property_parse_sdf_post';
import type {CompoundPropertyBase} from '../models/CompoundPropertyBase';
import type {CreateCompoundProperty} from '../models/CreateCompoundProperty';

import type {CancelablePromise} from '../core/CancelablePromise';
import type {BaseHttpRequest} from '../core/BaseHttpRequest';

export class CompoundPropertyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {
    }

    /**
     * Get Compound Properties
     * @returns CompoundPropertyBase Successful Response
     * @throws ApiError
     */
    public getCompoundProperties(): CancelablePromise<Array<CompoundPropertyBase>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/compound-property/get',
        });
    }

    /**
     * Create Compound Property
     * @param requestBody
     * @returns CompoundPropertyBase Successful Response
     * @throws ApiError
     */
    public createCompoundProperty(
        requestBody: CreateCompoundProperty,
    ): CancelablePromise<CompoundPropertyBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound-property/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Parse Sdf
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public parseSdf(
        formData: Body_parse_sdf_compound_property_parse_sdf_post,
    ): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/compound-property/parse-sdf',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
