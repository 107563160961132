import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BiotechIcon from '@mui/icons-material/Biotech';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import logoLight from "../assets/logo-light.svg"
import logoDark from "../assets/logo-dark.svg"

import {useUIStore} from '../store/store';
import {Link} from 'react-location';
import {Avatar, Menu, MenuItem, Switch, Tooltip} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import React, {useState} from 'react';


const DrawerItems = [
    {
        label: "Compounds",
        icon: <BiotechIcon/>,
        link: "/"
    },
    // {
    //     label: "Orders",
    //     icon: <LocalShippingIcon/>,
    //     link: "/orders"
    // },
    {
        label: "Upload",
        icon: <UploadFileIcon/>,
        link: "/upload"
    }
]

const barHeight = 80;
const drawerWidth = 200;
const drawerWidthClosed = 40;


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerWidthClosed,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    height: barHeight,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: barHeight,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export const Layout = ({children}: { children: JSX.Element }) => {
    const theme = useTheme();
    const {drawerOpen, toggleDrawerOpen, menuOpen, toggleMenuOpen, themeMode, toggleThemeMode} = useUIStore()
    const {logout, user} = useAuth0();
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={drawerOpen}>
                <Toolbar>
                    <Box sx={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawerOpen}
                            edge="start"
                            sx={{
                                backgroundColor: 'transparent',
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                },
                                marginRight: 5,
                                ...(drawerOpen && {opacity: 0, pointerEvents: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img style={{maxWidth: 300}} src={themeMode === 'light' ? logoLight : logoDark} alt={'logo'}/>
                        <Tooltip title="Open settings">
                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                                onClick={(e) => {
                                    handleOpenMenu(e);
                                    toggleMenuOpen();
                                }}
                            >
                                <Avatar alt={user?.name || 'X'} src={user?.picture}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchor}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={menuOpen}
                            onClose={toggleMenuOpen}
                        >
                            <MenuItem onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                            <MenuItem>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography noWrap component="div">Dark Mode</Typography>
                                    <Switch checked={themeMode === 'dark'} onChange={toggleThemeMode}/>
                                </Box>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={drawerOpen}>
                <DrawerHeader>
                    <IconButton onClick={toggleDrawerOpen}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {DrawerItems.map((item) => (
                        <ListItem key={item.label} disablePadding sx={{display: 'block'}}>
                            <Link to={item.link}
                                  style={{color: theme.palette.text.primary, textDecoration: "none"}}
                                  getActiveProps={() => ({style: {color: theme.palette.primary.main}})}
                            >
                                {({isActive}) => {
                                    return (
                                        <>
                                            <ListItemButton
                                                selected={isActive}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: drawerOpen ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
                                                        minWidth: 0,
                                                        mr: drawerOpen ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.label} sx={{
                                                    opacity: drawerOpen ? 1 : 0,
                                                    textDecoration: 'none',
                                                    color: 'inherit'
                                                }}/>
                                            </ListItemButton>
                                        </>
                                    )
                                }}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" display={'flex'} flexDirection={'column'}>
                <DrawerHeader/>
                <Box height={"90vh"} width={"96vw"} style={{overflow: 'scroll'}}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}