/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {Project} from '../models/Project';

import type {CancelablePromise} from '../core/CancelablePromise';
import type {BaseHttpRequest} from '../core/BaseHttpRequest';

export class ProjectService {

    constructor(public readonly httpRequest: BaseHttpRequest) {
    }

    /**
     * Get Projects
     * @returns Project Successful Response
     * @throws ApiError
     */
    public getProjects(): CancelablePromise<Array<Project>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/get',
        });
    }

}
