import {createTheme, ThemeProvider} from '@mui/material';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Outlet, Router} from 'react-location';
import {Layout} from './components/Layout';
import Spinner from './components/Spinner';
import {location, routes} from './router';
import {useRdkitStore, useUIStore} from './store/store';
import {Auth0Provider} from './providers/auth0Provider';

// test
function App() {
    const themeMode = useUIStore((state: any) => state.themeMode)
    const setRdkit = useRdkitStore((state: any) => (state.setRdkit))

    setRdkit()

    const createdTheme = createTheme({
            palette: {
                mode: themeMode,
                ...(themeMode === 'light'
                    ? {
                        primary: {
                            main: '#ffc107',
                        },
                        secondary: {
                            main: '#343a40',
                        }
                    }
                    : {
                        primary: {
                            main: '#ffc107',
                        },
                        secondary: {
                            main: '#343a40',
                        }
                    }),
            },
        }
    );

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Router routes={routes} location={location} defaultPendingElement={<Spinner/>}>
                <Auth0Provider>
                    <ThemeProvider theme={createdTheme}>
                        <Layout>
                            <Outlet/>
                        </Layout>
                    </ThemeProvider>
                </Auth0Provider>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
